var togglers = {
	$body: $('body'),
	$header: $('.header'),
	$toggler: $('.toggler'),
	
	init: function () {
		this.bindEvents();
	},

	bindEvents: function() {
		var self = this;
		this.$toggler.on('click', function(e) {
			e.preventDefault();
			self.onClickedToggler(this);
		});
	},

	onClickedToggler: function(el) {
		if ($(el).hasClass('header__toggle') && this.$body.hasClass('open-menu')) {
			this.closeSection();
		} else if ($(el).hasClass('go-to') && this.$body.hasClass('open-menu')) {
				this.closeSection();
		} else {
			var section = $(el).data('show-section');
			this.openSection(section);
		}
	},

	openSection: function(el) {
		this.$body
			.addClass('open-menu')
			.toggleClass('dark-menu', $(el).hasClass('do-dark-menu'));
		this.$header.addClass('is-active');
		$(el).addClass('is-visible');
	},

	closeSection: function () {
		this.$body
			.removeClass('open-menu')
			.removeClass('dark-menu');
		this.$header.removeClass('is-active');
		$('.is-menu.is-visible').removeClass('is-visible');
	}
};

var fixMenu = {
	init: function () {

		$(window).bind('scroll', function () {
		    if ($(window).scrollTop() > 80) {
		    	$('header.header').addClass('fixed');
				$('.menu.is-menu').addClass('fixed');
		    } else {
		    	$('header.header').removeClass('fixed');
		    	$('.menu.is-menu').removeClass('fixed');
		    }
		});		
	}
};

fixMenu.init();
togglers.init();